<template>
  <div class="hero">
    <div class="back"></div>
    <v-container>
      <section>
        <v-row>
          <v-col
            cols="12"
            md="6"
            class="d-flex align-center justify-center flex-column"
          >
           <div  class="d-flex  flex-column">
            <h1 align="center" class="text-principal white--text">sistema de <br> acreditación</h1>
           <!--  <v-img
              max-height="200"
              width="600"
              contain
              src="../assets/img/policia.png"
            ></v-img> -->
            <div class="contain__text"><span class="texto__conect">conectados con la seguridad</span> </div>
           </div>
          </v-col>
          <v-col
            cols="12"
            md="6"
            class="d-flex align-center justify-center flex-column"
          >
            <v-img :width="this.$vuetify.breakpoint.smAndUp ? 500 : 220" src="../assets/img/sol.png"></v-img>
            <div class="d8 d-flex align-center">
              <v-img :width="this.$vuetify.breakpoint.smAndUp ? 170 : 100"  src="../assets/img/escudo.png"></v-img>
              <div class="divisor"></div>
              <span class="textoPolicia white--text font-weight-light"
                ><h2>
                  Direccion de Telecomunicaciones <br />
                  y Emergencias Policiales D-8
                </h2></span
              >
            </div>
          </v-col>
        </v-row>
      <v-row>
        <v-col cols="12">
          <div class="contenedor input__manual pa-5">
        <v-text-field
          label="Ingrese D.N.I"
          v-model="dniInput"
          v-on:keyup.
          type="number"
        ></v-text-field>
        <v-btn block color="success" @click="actualizarLista('dos')">
          Consultar
        </v-btn>
      </div>

        </v-col>
      </v-row>
      </section>
      <div class="contenedor cards">
       <section v-if="this.$vuetify.breakpoint.smAndUp" align="center">
       
        
        
          <span class="titulo__scan">Coloque el dni en el scanner para su acreditacion</span>
          <br>
          <span class="titulo__scan">Policia de San Juan</span>

       
       
       </section>
        <v-text-field
          class="te"
          ref="miInput"
          v-model="valorCodigo"
          v-on:keyup.enter="actualizarLista('tres')"
          autofocus
          type="password"
        ></v-text-field>
      </div>
      <v-dialog v-model="dialog" width="700">
      <v-card color="blue-grey darken-3" dark>
        <v-img
        
          src="@/assets/img/fns.jpeg"
          :height="pantalla > 1000 ? 600 : 0"
          width="700"
          max-height="400"
        ></v-img>
        <v-card-title style="justify-content: center">
          <span class="titulo-card font-weight-bold" style="text-align: center" >{{ message }}</span>
        </v-card-title>

        <v-card-text
          v-if="respuesta.response"
          class="text-h2"
          style="text-align: center;color:white"
        >
          "{{ nombre }}"
        </v-card-text>

        <v-card-text
          v-else
          class="text-h2 font-weight-bold"
          style="text-align: center"
        >
      
          <span style="color: white; font-size: 40px; display: block"
            >Registro no encontrado</span
          >
        </v-card-text>
      </v-card>
    </v-dialog>
    </v-container>
    
  </div>
  <!-- <section id="hero" style="position: relative">
    <div class="uno">
      <v-img  :aspect-ratio="16/9" cover  :src='pantalla >= 1000 ? imagenes[0].img : imagenes[1].img  '></v-img>
      
    </div>
    <v-container fluid id="features" class="dos">
      <v-row align="center" justify="center">
        <v-col cols="10" class="cards">
          <v-row align="center" justify="space-around">
            <v-col cols="12" class="text-center">
              <h1 class="font-weight-light display-2">
                Coloque su D.N.I en el Scanner para su acreditacion
              </h1>
              <h1 class="font-weight-light">
                Policia de San Juan 
               
              </h1>

            
            </v-col>
            <v-col
              cols="12"
              sm="4"
              class="text-center cards"
              v-for="(feature, i) in features"
              :key="i"
            >
              <v-hover v-slot:default="{ hover }">
                <v-card
                  class="card"
                  shaped
                  :elevation="hover ? 10 : 4"
                  :class="{ up: hover }"
                >
                  <v-img
                    :src="feature.img"
                    class="white--text align-end"
                    gradient="to bottom, rgba(0,0,0,.1), rgba(0,0,0,.5)"
                    height="300px"
                  >
                    <v-card-title
                      v-text="feature.title"
                      style="font-size: 30px"
                    ></v-card-title>
                  </v-img>
                </v-card>
              </v-hover>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
      <div class="contenedor cards">
        <v-text-field
          class="te"
          ref="miInput"
          v-model="valorCodigo"
          v-on:keyup.enter="actualizarLista('tres')"
          autofocus
          type="password"
        ></v-text-field>
      </div>
      <div class="contenedor input__manual">
        <v-text-field
          label="Ingrese D.N.I"
          v-model="dniInput"
          v-on:keyup.
          type="number"
        ></v-text-field>
        <v-btn block color="success" @click="actualizarLista('dos')">
          Consultar
        </v-btn>
      </div>
    </v-container>
    <v-dialog v-model="dialog" width="700">
      <v-card color="#ffddd1" dark>
        <v-img
        
          src="@/assets/img/fns.jpeg"
          :height="pantalla > 1000 ? 600 : 0"
          width="700"
        ></v-img>
        <v-card-title style="justify-content: center">
          <span class="titulo-card font-weight-bold">Bienvenido/a</span>
        </v-card-title>

        <v-card-text
          v-if="respuesta.response"
          class="text-h2"
          style="text-align: center;color:#252120"
        >
          "{{ nombre }}"
        </v-card-text>

        <v-card-text
          v-else
          class="text-h2 font-weight-bold"
          style="text-align: center"
        >
      
          <span style="color: #252120; font-size: 40px; display: block"
            >Dirigirse por Recepcion</span
          >
        </v-card-text>
      </v-card>
    </v-dialog>
    <div class="svg-border-waves">
      <img src="~@/assets/img/wave2.svg" />
    </div>
  </section> -->
</template>

<script>
// import { json } from 'body-parser';
import axios from "axios";

export default {
  data() {
    return {
      imgf: "@/assets/img/fns1.jpeg",
      pantalla: "",
      imagenes: [
        {
          img: require("@/assets/img/fns1.jpeg"),
          title: "Conferencias",
          text: "Lorem ipsum dolor sit amet consectetur adipisicing elit.",
        },
        {
          img: require("@/assets/img/fns.jpeg"),
          title: "Seminarios",
          text: "Lorem ipsum dolor sit amet consectetur adipisicing elit.",
        },
        {
          img: require("@/assets/img/taller.jpg"),
          title: "Talleres",
          text: "Lorem ipsum dolor sit amet consectetur adipisicing elit.",
        },
      ],
      dialog: false,
      videoId: "i8IvvHJssWE",
      valorCodigo: "",
      dniInput: "",
      datos: {},
      respuesta: "",
      pago: "",
      nombre: "",
      message: "",
      apellido: "",
      features: [
        {
          img: require("@/assets/img/confe.jpg"),
          title: "Conferencias",
          text: "Lorem ipsum dolor sit amet consectetur adipisicing elit.",
        },
        {
          img: require("@/assets/img/taller.jpg"),
          title: "Seminarios",
          text: "Lorem ipsum dolor sit amet consectetur adipisicing elit.",
        },
        {
          img: require("@/assets/img/conf.jpg"),
          title: "Talleres",
          text: "Lorem ipsum dolor sit amet consectetur adipisicing elit.",
        },
      ],
    };
  },

  created() {
    //  this.$refs.miInput.$el.focus()
    this.pantalla = screen.height;
    console.log("pantalla", this.pantalla);
  },

  methods: {
    async actualizarLista(flat) {
      console.log("entre desde la app", flat);
      // .get(
      //   `http://192.168.20.160/authenticate`,
      //   {params:{DNI:`${this.datos[4]}`}}
      // )
      let dni = "";
      if (flat == "tres") {
        this.datos = this.valorCodigo.split('"');
        dni = this.datos[4];
        console.log("estoy ", this.datos);
      } else {
        dni = this.dniInput;
        this.nombre = "";
        console.log("acaaaa");
      }
      console.log(dni);
      await axios
        .post(`https://preprod.electro3.com.ar/authenticate`, { DNI: `${dni}` })
        .then((res) => {
          // this.respuesta = res.data[0].estado;
          this.respuesta = res.data;

          console.log("respuesta", this.respuesta);
          this.nombre = this.respuesta.response.jerarquia +" "+this.respuesta.response.nombre;
          //console.log(this.respuesta.response);
          if(this.respuesta.response.ingreso){
            this.message = "Fin del Servicio"
          }else{
            this.message = "Bienvenido/a"
          }
          //this.message = this.respuesta.message;
        })
        .catch((e) => {
          console.log(e);
        });

      this.dialog = true;
      if (this.respuesta.response) {
        console.log("aac", this.respuesta);
        setTimeout(() => {
          this.dialog = false;
          (this.valorCodigo = ""), (this.respuesta = "");
        }, 4000);
        this.dniInput = "";
      } else {
        setTimeout(() => {
          this.dialog = false;
          (this.valorCodigo = ""), (this.respuesta = "");
        }, 8000);
        this.dniInput = "";
      }
    },
  },
};
</script>

<style>
@import url('https://fonts.googleapis.com/css2?family=Chakra+Petch:wght@500&display=swap');
.back{
  width: 100%;
  height: 100%;
  position: absolute;
  background:black;
  opacity: .3;

}

.titulo__scan{
  font-size: 3rem;
  color: white;

}
.texto__conect{
  font-size: 2rem;
  text-transform: uppercase;
  color: white;
  font-family: 'Chakra Petch', sans-serif;
}
.contain__text{
  margin: auto;
  background-color: rgb(16 83 153);
  padding: 10px;
  border-radius: 20px;
}
.text-principal {
  text-transform: uppercase;
  font-size: 6rem;
  font-family: 'Chakra Petch', sans-serif;
  
}
.divisor {
  border-left: 3px solid white;
  height: 100px;
  margin-right: 10px;
}
.img__escudo {
  width: 100px !important;
  height: 50px;
}

.hero {
  width: 100%;
  height: 100vh;
  background-image: url("../assets/img/backfns.png");
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  display: flex;
  justify-content: center;
  align-items: center;
}
.uno {
  height: 50vh;
  width: 100%;
  display: flex;
  position: relative;
  overflow: hidden;
}
.dos {
  height: 50vh;
}
.img2 {
  width: 45%;
  height: 100%;
  object-fit: contain;
}
.svg-border-waves .v-image {
  position: absolute;
  bottom: 0;
  left: 0;
  height: 3rem;
  width: 100%;
  overflow: hidden;
}

#hero {
  z-index: 0;
}
.svg-border-waves img {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  margin-bottom: -2px;
  z-index: -1;
}

.card {
  min-height: 300px;
  padding: 10px;
  transition: 0.5s ease-out;
}

.card .v-image {
  margin-bottom: 15px;
  transition: 0.75s;
}
.titulo-card {
  font-size: 3.5rem;
}
.card h1 {
  margin-bottom: 10px;
}

.zoom-efect {
  transform: scale(1.1);
}
.input__manual {
  display: none;
}
@media screen and (max-width: 650px) {
  .cards {
    display: none;
  }
  .texto__conect{
  font-size: 1.2rem;
  text-transform: uppercase;
  color: white;
  font-family: 'Chakra Petch', sans-serif;
}
.textoPolicia{
  font-size: .7rem;
}

}

@media screen and (max-width: 650px) {
  .input__manual {
    position: absolute;
    bottom: -110px;
    left: 0;
    right: 0;
    display: block;
    background: white;
    border-radius: 10px;
  }
  .titulo-card {
    font-size: 2.5rem;
  }
  .text-principal {
  text-transform: uppercase;
  font-size: 3rem;
  font-family: 'Chakra Petch', sans-serif;
  
}
}
</style>

<style>
section {
  position: relative;
}
</style>
